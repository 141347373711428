import { getFilestackUrlForFile, previewFilestackFile } from './Filestack';

const fileObjectFromUrlName = (url, name) => ({
  url,
  filename: name || '',
});

const bindFilestackPreview = () => {
  document.querySelectorAll('.filestack-preview').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      previewFilestackFile(e.target);
    });
  });
};

const bindFilestackRemove = (filestackButton) => {
  document.querySelectorAll('.filestack-remove').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();

      if (e.currentTarget.dataset.fieldSelector == null
        || document.querySelector(e.currentTarget.dataset.fieldSelector) == null) {
        window.Rollbar.error('A target hidden field selector must be provided for a filepicker');
        return;
      }

      const inputField = document.querySelector(e.currentTarget.dataset.fieldSelector);
      const nameField = document.querySelector(e.currentTarget.dataset.nameSelector);

      inputField.value = null;
      if (nameField) {
        nameField.value = null;
      }

      // eslint-disable-next-line no-use-before-define
      renderFileDetails(filestackButton);
    });
  });
};

function renderFileDetails(el) {
  if (el.dataset.fileDetailsContainer == null) {
    return;
  }

  const detailsContainer = document.querySelector(el.dataset.fileDetailsContainer);
  if (detailsContainer == null) {
    window.Rollbar.error('The details container element was not found for uploaded files');
    return;
  }

  if (el.dataset.fieldSelector == null
    || document.querySelector(el.dataset.fieldSelector) == null) {
    window.Rollbar.error('A target hidden field selector must be provided for a filepicker');
    return;
  }

  let nameField = null;
  if (el.dataset.nameSelector) {
    nameField = document.querySelector(el.dataset.nameSelector);
  }

  const inputField = document.querySelector(el.dataset.fieldSelector);
  const url = inputField.value;

  const file = fileObjectFromUrlName(url, nameField?.value);

  detailsContainer.innerHTML = '';
  const template = document.getElementById('filestack-attachment-detail-template');
  const clone = template.content.firstElementChild.cloneNode(true);

  const filename = clone.querySelector('.filestack-filename');
  filename.innerHTML = file.filename;

  const downloadLink = clone.querySelector('.filestack-download');
  downloadLink.href = getFilestackUrlForFile(file);

  const previewLink = clone.querySelector('.filestack-preview');
  previewLink.href = getFilestackUrlForFile(file);

  const removeLink = clone.querySelector('.filestack-remove');
  removeLink.dataset.fieldSelector = el.dataset.fieldSelector;

  detailsContainer.appendChild(clone);

  if (url.length > 0) {
    detailsContainer.style.display = null;
  } else {
    detailsContainer.style.display = 'none';
  }

  bindFilestackPreview();
  bindFilestackRemove(el);
}

const bindFilestackSingle = (el) => {
  el.addEventListener('click', (e) => {
    if (e.target.dataset.fieldSelector == null
      || document.querySelector(e.target.dataset.fieldSelector) == null) {
      window.Rollbar.error('A target hidden field selector must be provided for a filepicker');
      return;
    }

    const options = JSON.parse(e.target.dataset.options || '{}');
    options.maxFiles = 1;

    if (options.transformations != null && options.transformations.crop != null) {
      delete options.transformations;
    }

    options.onUploadDone = (data) => {
      if (data.filesFailed.length > 0) {
        window.Rollbar.error('Failed to upload Files: ');
        window.Rollbar.error(data.filesFailed);
        return;
      }

      if (data.filesUploaded.length > 1) {
        window.Rollbar.error('FS Single only supports a single file attachment');
        return;
      }

      const inputField = document.querySelector(e.target.dataset.fieldSelector);
      const nameField = document.querySelector(e.target.dataset.nameSelector);

      if (data.filesUploaded.length > 0) {
        const uploadedFile = data.filesUploaded[0];
        inputField.value = uploadedFile.url;
        if (nameField) {
          nameField.value = uploadedFile.filename;
        }
      }
      renderFileDetails(e.target);
    };

    const defaults = {
      fromSources: ['local_file_system', 'imagesearch'],
    };
    const updatedOptions = { ...defaults, ...options };

    window.fpGroupize.picker(updatedOptions).open();
  });
};

const init = () => {
  if (window.fpGroupize == null) {
    return;
  }

  document.querySelectorAll('[data-filestack]').forEach((el) => {
    renderFileDetails(el);
    bindFilestackSingle(el);
  });
};

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('filestack:rebind', init);
