function handleInput(searchInput, searchResultsElement, displayCallback) {
  const minChars = 2;
  const inputValue = searchInput.value.trim();

  searchResultsElement.innerHTML = "";

  const headers = {};

  if (searchInput.dataset.contentType) {
    headers["Content-Type"] = searchInput.dataset.contentType;
  }

  if (inputValue.length >= minChars) {
    fetch(`${searchInput.dataset.typeaheadUrl}/?q=${inputValue}`, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => displayCallback(data, searchResultsElement))
      .catch((error) => window.Rollbar.error("Error:", error));
  }
}

function displayResults(results, searchResultsElement) {
  searchResultsElement.innerHTML = "";

  if (results.length === 0) {
    const noResultsItemItem = document.createElement("div");
    noResultsItemItem.className = "form-item-typeahead__noresults";
    noResultsItemItem.textContent = "No results found";
    searchResultsElement.appendChild(noResultsItemItem);
  } else {
    results.forEach((result) => {
      const divItem = document.createElement("div");

      divItem.className = "form-item-typeahead__option";
      divItem.setAttribute("data-value", result.id);
      divItem.textContent = result.name;
      searchResultsElement.appendChild(divItem);
    });
  }
}
function handleResultClick(
  event,
  selectedOptionInput,
  searchInput,
  searchResultsElement,
) {
  const { target } = event;

  if (target.classList.contains("form-item-typeahead__option")) {
    const dataValue = target.getAttribute("data-value");
    const optionName = target.textContent;

    selectedOptionInput.value = dataValue;
    searchInput.value = optionName;
    searchResultsElement.innerHTML = "";
  }
}

export const initTypeaheadSearch = (typeaheadContainer) => {
  const typeaheadDelay = typeaheadContainer.dataset.typeaheadDelay || 300;

  if (!window.typeaheadTimers) {
    window.typeaheadTimers = {};
  }

  const searchInput = typeaheadContainer.querySelector(
    ".form-item-typeahead__search",
  );
  const searchResultsElement = typeaheadContainer.querySelector(
    ".form-item-typeahead__options",
  );
  const selectedOptionInput = typeaheadContainer.querySelector(
    ".form-item-typeahead--selected",
  ); // Hidden input

  document.addEventListener("mousedown", (e) => {
    if (
      !e.target.classList.contains("form-item-typeahead__option") &&
      e.pointerId > 0 &&
      searchResultsElement.innerHTML !== ""
    ) {
      searchResultsElement.innerHTML = "";
    }
  });

  searchInput.addEventListener("keyup", (event) => {
    const timer = window.typeaheadTimers[typeaheadContainer.id];
    if (timer) {
      clearTimeout(timer);
    }
    window.typeaheadTimers[typeaheadContainer.id] = setTimeout(() => {
      if (event.key === "Escape" && searchResultsElement.innerHTML !== "") {
        searchResultsElement.innerHTML = "";
      } else {
        handleInput(searchInput, searchResultsElement, displayResults);
      }
    }, typeaheadDelay);
  });

  searchInput.addEventListener("blur", () => {
    searchResultsElement.innerHTML = "";
  });

  searchResultsElement.addEventListener("mousedown", (event) => {
    handleResultClick(
      event,
      selectedOptionInput,
      searchInput,
      searchResultsElement,
    );
  });
};

const init = () => {
  document.querySelectorAll("[data-typeahead-search]").forEach((el) => {
    initTypeaheadSearch(el);
  });
};

document.addEventListener("DOMContentLoaded", init);
