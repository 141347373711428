import '../styles/application.scss';
import '../stylesheets/application.scss';

/* eslint-disable import/newline-after-import */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-multi-assign */
/* eslint-disable global-require */
import $ from 'jquery';
window.$ = window.JQuery = window.jQuery = $;
/* eslint-disable import/extensions */
/* eslint-disable import/first */
// eslint-disable-next-line import/no-unresolved
require('semantic/semantic.min.js');

import Rails from "@rails/ujs";

// eslint-disable-next-line no-underscore-dangle
if (!window._rails_loaded) {
  Rails.start();
}

import "@hotwired/turbo-rails";
import * as Turbo from "@hotwired/turbo";
Turbo.session.drive = false;
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars

require('@nathanvda/cocoon');

import '../controllers/global_controllers';
import '../controllers/admin_controllers';

// ThirdParty Plugins
import '../src/plugins/Clipboard';
import initClipboardBtn from '../src/plugins/Clipboard';
import '../src/plugins/ColorPicker';
import '../src/plugins/Filestack';
import '../src/plugins/FilestackSingle';
import '../src/plugins/Gmap';
import '../src/plugins/LocationAutocomplete';
import '../src/plugins/Popup';
import '../src/plugins/Print';
import '../src/plugins/Quill';
import '../src/plugins/SortableJS';
import '../src/plugins/Swiper';
import '../src/plugins/Tabby';
import '../src/plugins/Toastr';
import '../src/plugins/UserPilot';
import { initCustomCheckboxes, initCustomSelects, initRangeInput } from '../src/plugins/FormFields';

import '../src/custom/Utils';
import '../src/custom/UrlParams';
import '../src/custom/ListScrollTo';
import '../src/custom/HeaderInit';

import { initHideShowLinks } from '../src/custom/InitApp';
import '../src/custom/Modal';
import '../src/custom/ScrollTo';
import '../src/custom/TableShadow';
import { InitDashboardWebsiteContentScripts } from '../src/custom/InitDashboard';
import '../src/custom/PasswordChange';
import { initDatePicker } from '../src/custom/DatetimeInit';
import '../src/custom/batch_actions';
import '../src/custom/multi_select_table';
import '../src/custom/organizations';
import '../src/custom/TableAccordion';
import '../src/custom/Questions';

// // All component
import '../src/custom/components';

// // All admin scripts
import '../src/custom/admin';

// // All event scripts
import '../src/custom/events';
import '../src/custom/events/VideoBackground';
import '../src/custom/registration/Medpro';

import { initCookiesAcceptance } from '../src/custom/cookies';

import 'imagesloaded';

document.addEventListener('DOMContentLoaded', () => {
  initCookiesAcceptance();
  initHideShowLinks();
  initDatePicker();
  initRangeInput();
  document.dispatchEvent(new Event('colorpicker:init'));
  initCustomCheckboxes();
  initCustomSelects();
  InitDashboardWebsiteContentScripts();
  initClipboardBtn('.clipboard-btn');
  document.dispatchEvent(new Event('airportselector:rebind'));
});
