const init = () => {
  // Check length of options
  const optionLength = (optionContainer) => {
    const countedOptions = Array.from(optionContainer.querySelectorAll('.form-item-select__option'))
      .filter((option) => !option.classList.contains('tw-hidden'));
    return countedOptions.length;
  };
  // Toggle function
  const toggleDropdown = (container) => {
    const optionsContainer = container.querySelector('.form-item-select__options');
    container.classList.toggle('form-item-select--open');
    optionsContainer.classList.toggle('tw-hidden');
    if (optionLength(optionsContainer) < 6 && container.classList.contains('form-item-select--open')) {
      optionsContainer.classList.add('tw-overflow-hidden');
    } else {
      optionsContainer.classList.remove('tw-overflow-hidden');
    }
  };
  // Reveal Options
  const revealOptions = (container) => {
    const optionsContainer = container.querySelector('.form-item-select__options');
    const noResultsOption = optionsContainer.querySelector('.form-item-select__option--no-results');
    optionsContainer.querySelectorAll('.form-item-select__option').forEach((elem) => {
      elem.classList.remove('tw-hidden', 'active');
    });
    if (noResultsOption) {
      noResultsOption.remove();
    }
  };
  // Filter Options
  const filterOptions = (container, value) => {
    const optionsContainer = container.querySelector('.form-item-select__options');
    const hasNoResults = optionsContainer.querySelector('.form-item-select__option--no-results');
    optionsContainer.querySelectorAll('.form-item-select__option:not(.form-item-select__option--no-results)').forEach((elem) => {
      if (elem.innerText.toLowerCase().indexOf(value.toLowerCase()) === -1) {
        elem.classList.add('tw-hidden');
      } else {
        elem.classList.remove('tw-hidden', 'active');
      }
    });
    const filteredOptions = optionsContainer.querySelectorAll('.form-item-select__option:not(.tw-hidden):not(.form-item-select__option--no-results)');
    if (filteredOptions.length === 1) {
      const onlyOption = filteredOptions[0];
      onlyOption.classList.add('active');
    }
    if (filteredOptions.length === 0 && !hasNoResults) {
      const noResultsOption = document.createElement('div');
      noResultsOption.classList.add('form-item-select__option', 'form-item-select__option--no-results');
      noResultsOption.innerText = 'No results found';
      optionsContainer.appendChild(noResultsOption);
    } else if (hasNoResults && filteredOptions.length > 0) {
      optionsContainer.querySelector('.form-item-select__option--no-results').remove();
    }
    if (optionLength(optionsContainer) < 6) {
      optionsContainer.classList.add('tw-overflow-hidden');
    } else {
      optionsContainer.classList.remove('tw-overflow-hidden');
    }
  };
  // Get dropdown value
  const getDropdownValue = (container) => {
    const valueContainer = container.querySelector('input[type="hidden"]');
    return valueContainer ? valueContainer.value : '';
  };
  // Reveal selected option
  const revealSelected = (select) => {
    const selectedContainer = select.querySelector('.form-item-select__selected');
    if (getDropdownValue(select) && selectedContainer.classList.contains('tw-hidden')) {
      selectedContainer.classList.remove('tw-hidden');
    }
  };
  // Update Value
  const updateValue = (target, select) => {
    const selectedContainer = select.querySelector('.form-item-select__selected');
    const valueContainer = select.querySelector('input[type="hidden"]');
    const nameField = select.querySelector('.form-item-select--name');
    selectedContainer.innerText = target.innerText;
    valueContainer.value = target.dataset.value;
    target.classList.add('tw-hidden');
    if (nameField) {
      nameField.value = target.innerText;
    }
    document.dispatchEvent(new CustomEvent('searchdropdown:selected', { detail: { target, select } }));
  };
  // Dropdown keyboard navigation
  const keyboardNav = (e) => {
    const select = e.target.parentElement;
    const optionsContainer = select.querySelector('.form-item-select__options');
    if (e.target.value.length === 0) {
      revealOptions(select);
    } else if (e.target.value.length > 0) {
      filterOptions(select, e.target.value);
      if (optionsContainer.classList.contains('tw-hidden')) {
        optionsContainer.classList.remove('tw-hidden');
      }
    }
    if (e.key === 'Escape') {
      // Show selected option if value exists
      revealSelected(select);
      // Close dropdown
      toggleDropdown(select);
    }
    if (e.key === 'Enter') {
      // Click first option in filtered list
      const firstOption = select.querySelector('.form-item-select__option:not(.tw-hidden)');
      if (firstOption) {
        // Click the option
        firstOption.click();
      }
    }
  };
  // Reveal search container
  const revealSearch = (searchContainer, targetClasses) => {
    // Hide selected container
    targetClasses.add('tw-hidden');
    // Focus on search
    searchContainer.focus();
    // Add event listener to search
    searchContainer.addEventListener('keyup', keyboardNav);
  };
  // Close all dropdowns
  const closeAll = (currentSelect) => {
    const currentSelectMenu = currentSelect.querySelector('.form-item-select__options');
    const openSelects = Array.from(document.querySelectorAll('.form-item-select__options:not(.tw-hidden)')).filter((elem) => {
      if (currentSelectMenu) {
        return elem !== currentSelectMenu;
      }
      return true;
    });
    openSelects.forEach((elem) => {
      toggleDropdown(elem.parentElement);
      revealSelected(elem.parentElement);
    });
  };
  // Bulk close all dropdowns, use for listeners
  const bulkClose = (e) => {
    if (!e.target.classList.contains('form-item-select__selected') && !e.target.classList.contains('form-item-select__search') && e.pointerId > 0) {
      document.querySelectorAll('.form-item-select--open .form-item-select__options:not(.tw-hidden)').forEach((elem) => {
        toggleDropdown(elem.parentElement);
        revealSelected(elem.parentElement);
      });
    }
  };
  // Route to functions based on click
  const clickRouter = (target, select) => {
    // Get elements
    const searchContainer = select.querySelector('.form-item-select__search');
    const targetClasses = target.classList;
    const selectClasses = select.classList;
    // Conditions
    const selectIsOpen = selectClasses.contains('form-item-select--open');
    const targetSearch = targetClasses.contains('form-item-select__search');
    const targetOption = targetClasses.contains('form-item-select__option');
    const targetSelected = targetClasses.contains('form-item-select__selected');
    const targetOptionsContainer = targetClasses.contains('form-item-select__options'); // When no results is clicked

    if (!selectIsOpen) { // Initial Case: Select is closed when the click happens
      document.addEventListener('click', bulkClose);
    }

    if (targetSearch) {
      if (selectIsOpen) {
        // Dropdown has value and selected container is hidden
        revealSelected(select);
      } else {
        // Search clicked and select is closed
        target.addEventListener('keyup', keyboardNav);
      }
    }

    if (targetOption) {
      // Option clicked and select is open
      updateValue(target, select);
      revealOptions(select);
      searchContainer.removeEventListener('keyup', keyboardNav);
      // Dropdown has value and selected container is hidden
      revealSelected(select);
    }

    if (targetSelected) {
      if (selectIsOpen) {
        // Selected container clicked and Dropdown has value
        if (getDropdownValue(select)) {
          revealSearch(searchContainer, targetClasses);
        } else {
        // Search container clicked and Dropdown doesn't have value
          // Prevent dropdown from closing
          return;
        }
      } else {
        revealSearch(searchContainer, targetClasses);
      }
    }

    if (selectIsOpen) { // Select is open when the click happens
      if (targetOptionsContainer) {
        // Prevent dropdown from closing
        return;
      }
      document.removeEventListener('click', bulkClose);
    }

    // Toggle dropdown open/close
    toggleDropdown(select);
  };
  // Get all dropdowns
  const dropdowns = document.querySelectorAll('.form-item-select--search');
  dropdowns.forEach((el) => {
    // Get initial value
    if (getDropdownValue(el)) {
      const initialTarget = Array.from(el.querySelectorAll('.form-item-select__option')).filter((elem) => elem.dataset.value === getDropdownValue(el))[0];
      updateValue(initialTarget, el);
      revealSelected(el);
    }
    // TODO: Bring this back when keyboard controls are added
    // Add event listeners to dropdown
    // el.addEventListener('focus', (e) => {
    //   e.preventDefault();
    //   // Close all dropdowns except current
    //   closeAll(e.currentTarget);
    //   // Route to functions based on click
    //   clickRouter(e.target, e.currentTarget);
    // });
    el.addEventListener('click', (e) => {
      e.preventDefault();
      // Close all dropdowns except current
      closeAll(e.currentTarget);
      // Route to functions based on click
      clickRouter(e.target, e.currentTarget);
    });
  });
};

document.addEventListener('DOMContentLoaded', init);
