import { toggleContent, isHidden } from '../Utils';

export const toggleClick = (elem) => {
  const contentContainer = document.querySelector(`[data-content="${elem.dataset.toggle}"]`);
  toggleContent(contentContainer, isHidden(contentContainer));

  const icon = elem.querySelector('.toggle-icon');
  if (icon) {
    icon.classList.toggle('toggle-active');
  }
};

export const initAccordion = (elem) => {
  elem.addEventListener('click', (e) => {
    if (elem?.type !== 'checkbox') {
      e.preventDefault();
    }
    toggleClick(elem);
  });
  if (elem.dataset.toggleDefaultActive === 'true') {
    toggleClick(elem);
  }
};

export const initAccordions = () => {
  document.querySelectorAll('[data-toggle]').forEach((el) => {
    initAccordion(el);
  });
};

export const randomizeAccordionSelector = (containerElem, toggleSelector) => {
  const uniqueSelector = `${toggleSelector}_${Math.random().toString(36)}`;

  containerElem.querySelectorAll(`[data-toggle='${toggleSelector}']`).forEach((el) => {
    el.dataset.toggle = uniqueSelector;
  });
  containerElem.querySelectorAll(`[data-content="${toggleSelector}"]`).forEach((el) => {
    el.dataset.content = uniqueSelector;
  });
  containerElem.querySelectorAll(`[data-toggle='${uniqueSelector}']`).forEach((el) => {
    initAccordion(el);
  });
};

document.addEventListener('DOMContentLoaded', initAccordions);
document.addEventListener('accordion:init', initAccordions);
