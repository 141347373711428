import { Controller } from "@hotwired/stimulus";
import consumer from "../../src/custom/channels/consumer";
import { poll } from "../../src/custom/components/ShortPolling";

export default class extends Controller {
  static targets = ["saving", "success", "error", "errorMessages"];

  setSuccess = (result) => {
    this.successTarget.classList.remove("tw-hidden");
    this.savingTarget.classList.add("tw-hidden");
    this.element.dispatchEvent(
      new CustomEvent("progresspolling:complete", {
        detail: { result },
      }),
    );
  };

  setError = (result) => {
    this.errorTarget.classList.remove("tw-hidden");
    this.savingTarget.classList.add("tw-hidden");
    if (this.errorMessagesTarget) {
      const messages = result?.push_status_message?.split(",");
      if (messages !== null && messages.length > 0) {
        messages.forEach((message) => {
          const errorItem = document.createElement("li");
          errorItem.appendChild(document.createTextNode(message));
          this.errorMessagesTarget.appendChild(errorItem);
        });
      }
    }
    document.dispatchEvent(
      new CustomEvent("progresspolling:error", {
        detail: { result },
      }),
    );
  };

  processResponse(data) {
    if (data?.push_status === "sent") {
      document.dispatchEvent(
        new CustomEvent("progressbar:update", {
          detail: { progress: 100 },
        }),
      );
      setTimeout(() => {
        this.setSuccess(data);
      }, 500);
      return true;
    }
    if (data?.push_status === "error") {
      this.setError(data);
      return true;
    }
    if (data?.push_status === "pending") {
      document.dispatchEvent(
        new CustomEvent("progressbar:update", {
          detail: { progress: data?.push_progress },
        }),
      );
    }

    return false;
  }

  connect() {
    if (this.element.dataset.pollingUrl) {
      poll(this.element.dataset.pollingUrl, this.processResponse.bind(this));
    } else {
      const uuid = this.element.dataset.channelUuid;
      this.channel = consumer.subscriptions.create(
        { channel: "ProgressChannel", uuid },
        {
          received: this.processResponse.bind(this),
        },
      );
    }
  }
}
