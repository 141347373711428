import { Controller } from "@hotwired/stimulus";
import { i18n } from "../../src/custom/Internationalization";
import { initTypeaheadSearch } from "../../src/custom/components/TypeaheadSearch";

export default class extends Controller {
  static targets = ["selected", "form", "selectedIds", "typeahead", "preselected"];

  connect = () => {
    this.setSelectedText();
    this.setFilters();
    if (this.hasTypeaheadTarget) {
      this.initTypeahead();
    }
  };

  initTypeahead = () => {
    const typeaheadContainer = this.typeaheadTarget.querySelector(
      "[data-typeahead-search]",
    );
    if (typeaheadContainer) {
      initTypeaheadSearch(typeaheadContainer);
    }
  };

  setSelectedText = () => {
    if (this.hasSelectedTarget) {
      const selectedCount =
        this.selectedIdsTargets.length > 0
          ? this.selectedIdsTargets.length
          : this.getCounts().selected;

      if (selectedCount) {
        const { translation } = this.selectedTarget.dataset;
        this.selectedTarget.innerText = i18n.t(translation, {
          selected_count: selectedCount,
        });
      } else {
        this.selectedTarget.classList.add("tw-hidden");
      }
    }
  };

  setFilters = () => {
    if (this.formTarget.dataset.filtersSet) {
      return;
    }

    const searchForm = document.querySelector("[data-filter-form]");
    const selectedIds = this.getSelectedIds();

    selectedIds.forEach((id) => {
      this.addHiddenField("selected_ids[]", id);
    });

    if (selectedIds.length > 0) {
      this.hideTypeahead();
    }

    if (searchForm) {
      const formData = new FormData(searchForm);
      formData.keys().forEach((key) => {
        if (formData.get(key)) {
          this.addHiddenField(key, formData.get(key));
        }
      });

      this.formTarget.dataset.filtersSet = "true";
    }
  };

  addHiddenField = (name, value) => {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);
    this.formTarget.append(input);
  };

  getSelectedIds = () => {
    const selectedItems = [];

    if (this.hasPreselectedTarget) {
      selectedItems.push(this.preselectedTarget.value);
    } else {
      const items = document.querySelectorAll(".select_row > input");
      items.forEach((el) => {
        if (el.checked) {
          selectedItems.push(el.dataset.recordId);
        }
      });
    }

    return selectedItems;
  };

  hideTypeahead = () => {
    if (this.hasTypeaheadTarget) {
      this.typeaheadTarget.classList.add("tw-hidden");
    }
  };

  getCounts = () => {
    const items = document.querySelectorAll(".select_row > input");
    if (items.length === 0) {
      return { selected: null, all: null };
    }

    const selectedItems = this.getSelectedIds();

    const listing = document.querySelector("[data-multiselect-listing]");
    const selectedAll = document.querySelector("#selected_all");
    const totalCount = listing.dataset.recordCount;
    if (selectedAll.value === "true" && selectedItems.length === items.length) {
      return { selected: totalCount, all: totalCount };
    }
    return { selected: selectedItems.length, all: items.length };
  };

  formSubmitted = (event) => {
    if (event.detail.success) {
      this.dispatch("success");
    }
  };
}
