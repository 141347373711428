export const bindSelectDropdowns = (el) => {
  const baseWidth = el.offsetWidth;
  // Hide standard select element
  el.classList.add('tw-hidden');
  // Get options
  const { options } = el;
  // Create custom select element
  const select = document.createElement('div');
  select.classList.add('form-item-select--js');
  // Close All Listener - Close all open selects when clicking outside
  const closeAll = () => {
    document.querySelectorAll('.form-item-select--js:not(.form-item-select--search) .form-item-select__options:not(.tw-hidden)').forEach((elem) => {
      elem.classList.add('tw-hidden');
      elem.parentElement.classList.remove('form-item-select--open');
    });
  };

  // Toggle function
  const toggleDropdown = (container) => {
    // Get Open Selects - TODO: Refactor this to not target Search Dropdowns and not need to target Search Dropdowns
    const openSelects = document.querySelectorAll('.form-item-select__options:not(.tw-hidden)');
    // Close all open selects except current
    if (openSelects.length > 0) {
      openSelects.forEach((elem) => {
        if (elem !== container) {
          elem.classList.add('tw-hidden');
          elem.parentElement.classList.remove('form-item-select--open');
        }
      });
    }
    let shouldRun = true;
    if (container.type && container.type === 'click') {
      container.target.querySelectorAll('.form-item-select__options').forEach((elem) => {
        elem.classList.add('tw-hidden');
        select.classList.remove('form-item-select--open');
      });
      shouldRun = false;
    }
    if (container && shouldRun) {
      if (container.classList.contains('tw-hidden')) {
        container.classList.remove('tw-hidden');
        select.classList.add('form-item-select--open');
        document.addEventListener('click', closeAll);
      } else {
        container.classList.add('tw-hidden');
        select.classList.remove('form-item-select--open');
        document.removeEventListener('click', closeAll);
      }
    }
  };
  // Check length of options
  const optionLength = (optionContainer) => {
    const countedOptions = Array.from(optionContainer.querySelectorAll('.form-item-select__option'))
      .filter((option) => !option.classList.contains('tw-hidden'));
    return countedOptions.length;
  };
  // Create container for selected option
  const selectedContainer = document.createElement('div');
  selectedContainer.classList.add('form-item-select__selected');
  selectedContainer.style.minWidth = `${baseWidth}px`;
  selectedContainer.addEventListener('click', (event) => {
    event.stopPropagation();
    // Get elements
    const selectedSelect = event.target.parentElement;
    const optionsContainer = selectedSelect.querySelector('.form-item-select__options');
    // Toggle options
    toggleDropdown(optionsContainer);
    // Check length of options
    if (optionLength(optionsContainer) < 6) {
      optionsContainer.classList.add('tw-overflow-hidden');
    } else {
      optionsContainer.classList.remove('tw-overflow-hidden');
    }
  });
  select.appendChild(selectedContainer);
  // Create container for options
  const optionsContainer = document.createElement('div');
  optionsContainer.classList.add('form-item-select__options');
  optionsContainer.classList.add('tw-hidden');
  select.appendChild(optionsContainer);
  // Copy options to custom select element
  for (let i = 0; i < options.length; i += 1) {
    // Create option element
    const option = document.createElement('div');
    option.classList.add('form-item-select__option');
    // Add hidden class to selected option and remove prompt if select has value
    if (i === el.selectedIndex) {
      option.classList.add('tw-hidden');
      selectedContainer.innerHTML = option.innerHTML;
    } if (!options[i].value) {
      option.classList.add('tw-hidden');
    }
    option.innerHTML = options[i].innerHTML;
    // Add click event listener to option
    option.addEventListener('click', (event) => {
      event.stopPropagation();
      // Get elements
      const localSelect = event.target.parentNode.parentNode.nextSibling;
      const selectedWrapper = event.target.parentNode.parentNode.querySelector('.form-item-select__selected');
      const optionsWrapper = event.target.parentNode;
      const localOptions = optionsWrapper.querySelectorAll('.form-item-select__option');
      // Update values
      localSelect.value = event.target.innerHTML;
      localSelect.selectedIndex = Array.from(options).findIndex((o) => o.text === event.target.textContent);
      localSelect.dispatchEvent(new Event('change'));
      selectedWrapper.innerHTML = event.target.innerHTML;
      // Toggle visibility
      selectedWrapper.classList.remove('tw-hidden');
      toggleDropdown(optionsWrapper);
      for (let increment = 0; increment < localOptions.length; increment += 1) {
        // Remove placeholders once activated
        if (!options[increment].value && options[increment].innerHTML === localOptions[increment].innerHTML) {
          localOptions[increment].remove();
        }
        localOptions[increment].classList.remove('tw-hidden');
      }
      event.target.classList.add('tw-hidden');
    });
    optionsContainer.appendChild(option);
  }
  // Add custom select element to DOM
  el.parentNode.insertBefore(select, el);
  // Update selected value
  selectedContainer.innerHTML = options[el.selectedIndex].innerHTML;
};

const init = () => {
  // Bind selects
  document.querySelectorAll('.form-item-select').forEach((el) => { bindSelectDropdowns(el); });
  // Bind selects in modal
  const container = document.querySelector('#modal_view');
  const observerOptions = {
    childList: true,
    attributes: true,
    // Omit (or set to false) to observe only changes to the parent node
    subtree: false,
  };

  const listen = (mutationList) => {
    if (mutationList[0].attributeName === 'class' && mutationList[0].target.classList.contains('visible') === true) {
      mutationList[0].target.querySelectorAll('.form-item-select').forEach((el) => {
        if (el.classList.contains('form-item-select') && !el.classList.contains('tw-hidden')) {
          bindSelectDropdowns(el);
        }
      });
    }
  };

  const observer = new MutationObserver(listen);
  if (container) {
    observer.observe(container, observerOptions);
  }
};

document.addEventListener('DOMContentLoaded', init);
