import { bindSelectDropdowns } from "../components/SelectDropdown";
import { showModal } from "../Modal";

const hideShowTableContainer = () => {
  const tableContainer = document.querySelector(
    "#add_from_concur_table_container",
  );
  const tableBody = tableContainer.querySelector("tbody");
  const tableBodyRows = tableBody.querySelectorAll("tr");
  if (tableBodyRows.length > 0) {
    tableContainer.classList.remove("tw-hidden");
  } else {
    tableContainer.classList.add("tw-hidden");
  }
};

const setExistingRegistrationId = (registrationId) => {
  const registrationIdField = document.querySelector(
    "#concur_existing_registration",
  );
  registrationIdField.value = registrationId;
};

const init = (e) => {
  showModal("#add_from_concur_modal");
  setExistingRegistrationId("");
  if (!document.querySelector("#add_from_concur_modal .form-item-select--js")) {
    document
      .querySelectorAll("#add_from_concur_modal .form-item-select")
      .forEach((el) => {
        bindSelectDropdowns(el);
      });
  }
  if (e?.detail?.registrationId) {
    setExistingRegistrationId(e.detail.registrationId);
  }
  hideShowTableContainer();
};

document.addEventListener("concur:init_add_from_concur", init);
