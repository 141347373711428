/* eslint-disable no-promise-executor-return */

// This is a short-polling implementation. In the past we've had problems with
// ActionCable on Heroku so going back to basics here.

// The callback function must return true/false. A false response indicates
// that the result was not "ready" yet and polling should continue.

// It is assumed that the HTTP result is a JSON payload.

const POLLING_THRESHOLD = 2000;

export const poll = async (url, callback) => {
  const response = await fetch(url);
  if (response.status === 502) {
    await new Promise((resolve) => setTimeout(resolve, POLLING_THRESHOLD));
    await poll(url, callback);
  } else if (response.status !== 200) {
    window.Rollbar.error(
      "Error polling for response",
      response.status,
      response.statusText,
      response.url,
    );
  } else {
    const message = await response.text();
    const result = JSON.parse(message);
    if (callback(result) === false) {
      await new Promise((resolve) => setTimeout(resolve, POLLING_THRESHOLD));
      await poll(url, callback);
    }
  }
};
