import Cleave from 'cleave.js';

const bindCleaveCard = (el, callback) => {
  try {
    const cleave = new Cleave(`#${el.id}`, {
      creditCard: true,
      onCreditCardTypeChanged(type) {
        if (callback && typeof (callback) === 'function') {
          callback(type);
        }
      },
    });
    return cleave;
  } catch (error) {
    window.Rollbar.error('Cleave initialization failed');
  }
  return false;
};

const init = () => {
  document.querySelectorAll('.card_input').forEach((el) => {
    bindCleaveCard(el);
  });
};

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('cleave:rebind', init);
